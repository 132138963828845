const french = {
  french: {
    translation: {
      "Create a new Wallet": "Créer un nouveau portefeuille",
      "Import an existing wallet": "Importer un portefeuille existant",
      "Secret Recovery Phrase": "Phrase secrète de récupération",
      "Please write them down or store it somewhere safe.":
        "Veuillez les noter ou les conserver dans un endroit sûr.",
      "Copy to clipboard": "Copier dans le presse-papier",
      "I have securely saved it": "Je l&#39;ai sauvegardé en toute sécurité",
      "Copied secret phrase": "Phrase secrète copiée",
      "Create Password": "Créer un mot de passe",
      "Setup a password to unlock your super wallet":
        "Configurez un mot de passe pour déverrouiller votre super portefeuille",
      "Enter password": "Entrer le mot de passe",
      "Confirm password": "Confirmez le mot de passe",
      "I agree with the terms and Conditions":
        "Je suis d&#39;accord avec les termes et conditions",
      "I agree with the": "je suis d&#39;accord avec le",
      "terms and Conditions": "Termes et conditions",
      Proceed: "Procéder",
      "You are all set!": "Vous êtes prêt!",
      "Congratulations!": "Toutes nos félicitations!",
      "Your Hedera super wallet is ready to go":
        "Votre super portefeuille Hedera est prêt à partir",
      "Let’s see my dashboard": "Voyons mon tableau de bord",
      "Enter 12, 24 word secret recovery phrase or private key to import your existing wallet":
        "Entrez une phrase de récupération secrète de 12, 24 mots ou une clé privée pour importer votre portefeuille existant",
      "Enter Secret Phase": "Entrez dans la phase secrète",
      "WALLET VALUE": "VALEUR DU PORTEFEUILLE",
      Send: "Envoyer",
      Purchase: "Acheter",
      Receive: "Recevoir",
      Swap: "Échanger",
      "My Domains": "Mes domaines",
      All: "Tous",
      "Manage Domains": "Gérer les domaines",
      "200+ extensions available": "Plus de 200 extensions disponibles",
      "Get a Domain": "Obtenir un domaine",
      "Available Tokens": "Jetons disponibles",
      "Manage Tokens": "Gérer les jetons",
      "My wallets": "Mes portefeuilles",
      "Create new wallet": "Créer un nouveau portefeuille",
      Wallets: "Portefeuilles",
      Mainnet: "Réseau principal",
      "All your real transactions here": "Toutes vos transactions réelles ici",
      Testnet: "Réseau de test",
      "Used for testing only": "Utilisé uniquement pour les tests",
      "Edit Wallet": "Modifier le portefeuille",
      Delete: "Supprimer",
      "Wallet name": "Nom du portefeuille",
      "Wallet Customization": "Personnalisation du portefeuille",
      Initial: "Initial",
      Character: "Personnage",
      Icon: "Icône",
      "Wallet Icon": "Icône de portefeuille",
      "Save changes": "Sauvegarder les modifications",
      "Connected Site": "Site connecté",
      "All your Domains here": "Tous vos domaines ici",
      "Manage & Track all your domains through your Web23 wallet":
        "Gérez et suivez tous vos domaines via votre portefeuille Web23",
      "Get Started": "Commencer",
      "Nothing to see here": "Rien à voir ici",
      "Add / Transfer / Get your new Domain today":
        "Ajoutez / Transférez / Obtenez votre nouveau domaine aujourd&#39;hui",
      "Search by name or address": "Recherche par nom ou adresse",
      PASTE: "PÂTE",
      "Enabled Tokens": "Jetons activés",
      "Associating a token with your wallet costs $0.05":
        "Associer un jeton à votre portefeuille coûte 0,05 $",
      "Search results for": "Résultats de recherche pour",
      Settings: "Paramètres",
      Preferences: "Préférences",
      "Expand View": "Agrandir la vue",
      Language: "Langue",
      Currency: "Devise",
      Network: "Réseau",
      Theme: "Thème",
      "Secret Phrase": "Phrase secrète",
      "Connected Sites": "Sites connectés",
      "Lock Wallet": "Verrouiller le portefeuille",
      "Terms and Privacy": "Termes et confidentialité",
      "Contact Support": "Contactez le support",
      "United States Dollar": "Dollar américain",
      Euro: "euro",
      "British Pound": "Livre sterling",
      "Indian Rupee": "roupie indienne",
      "Australian Dollar": "Dollar australien",
      "Canadian Dollar": "Dollar canadien",
      "Chinese Yuan": "Le yuan chinois",
      "Turkish Lira": "Lire turque",
      English: "Anglais",
      Español: "Espagnol",
      Italiano: "Italien",
      Polish: "polonais",
      Deutsch: "Allemand",
      French: "Français",
      Hindi: "Non",
      Arabic: "arabe",
      Mandarin: "mandarin",
      Japanese: "Japonais",
      System: "Système",
      Light: "Lumière",
      Dark: "Sombre",
      "Hover to view the phrase": "Survolez pour voir la phrase",
      "These words are the keys to your wallet":
        "Ces mots sont les clés de votre portefeuille",
      "Welcome back": "Content de te revoir",
      "Manage all your domains from one wallet":
        "Gérez tous vos domaines depuis un seul portefeuille",
      Unlock: "Ouvrir",
      "Forgot Password?": "Mot de passe oublié?",
      "Superrare will be able to": "Superrare pourra",
      "Connect with Web23": "Connectez-vous avec Web23",
      "Connect your wallet to dApps to get started":
        "Connectez votre portefeuille à dApps pour commencer",
      "No sites connected": "Aucun site connecté",
      "Wallet 1 isn’t connected to any sites":
        "Wallet 1 n&#39;est connecté à aucun site",
      "Manually Connect": "Connecter manuellement",
      "Connecting to this wallet": "Connexion à ce portefeuille",
      "Access your wallet address, activity and balance":
        "Accédez à l&#39;adresse, à l&#39;activité et au solde de votre portefeuille",
      "Request approval for transactions":
        "Demander l&#39;approbation des transactions",
      Connect: "Connecter",
      "Switch Wallet": "Changer de portefeuille",
      Connecting: "De liaison",
      "Connection successful": "Connexion réussie",
      "Connection Details": "Détails de connexion",
      "Remove Connection": "Supprimer la connexion",
      "Remove Connection?": "Supprimer la connexion ?",
      "Are you sure you want to remove connection with Superrare?":
        "Êtes-vous sûr de vouloir supprimer la connexion avec Superrare ?",
      Cancel: "Annuler",
      Remove: "Éliminer",
      "Privacy Statement": "Déclaration de confidentialité",
      "This Privacy Statement describes the privacy practices of Unlabelled for data that we collect: Through the software applications made available by us for use on or through computers and mobile devices (the “Apps”). Through our social media pages that we control from which you are accessing this Privacy Statement (collectively, our “Social Media Pages”). Through HTML-formatted email messages that we send you that link to this Privacy Statement and through your communications with us. Collectively, we refer to the Websites, the Apps and our Social Media Pages, as the “Online Services” and, together with offline channels, the “Services.” By using the Services, you agree to the terms and conditions of this Privacy Statement.":
        "Cette déclaration de confidentialité décrit les pratiques de confidentialité d&#39;Unlabelled pour les données que nous recueillons : via les applications logicielles que nous mettons à disposition pour une utilisation sur ou via des ordinateurs et des appareils mobiles (les &quot;applications&quot;). Par le biais de nos pages de médias sociaux que nous contrôlons à partir desquelles vous accédez à cette déclaration de confidentialité (collectivement, nos « pages de médias sociaux »). Par les e-mails au format HTML que nous vous envoyons et qui renvoient à la présente Déclaration de confidentialité et par vos communications avec nous. Collectivement, nous désignons les sites Web, les applications et nos pages de médias sociaux comme les « services en ligne » et, avec les canaux hors ligne, les « services ». En utilisant les Services, vous acceptez les termes et conditions de cette Déclaration de confidentialité.",
      "“Personal Data” are data that identify you as an individual or relate to an identifiable individual. At touch points throughout your guest journey, we sometimes collect Personal Data such as: Email address":
        "Les « données personnelles » sont des données qui vous identifient en tant qu&#39;individu ou se rapportent à un individu identifiable. Aux points de contact tout au long de votre parcours client, nous collectons parfois des Données personnelles telles que : Adresse e-mail",
      "We collect certain data from cookies, which are pieces of data stored directly on the computer or mobile device that you are using. Cookies allow us to collect data such as browser type, time spent on the Online Services, pages visited, referring URL, language preferences, and other aggregated traffic data. We use the data for security purposes, to facilitate navigation, to display data more effectively, to collect statistical data, to personalize your experience while using the Online Services and to recognize your computer to assist your use of the Online Services. We also gather statistical data about use of the Online Services to continually improve design and functionality, understand how they are used and assist us with resolving questions.":
        "Nous collectons certaines données à partir de cookies, qui sont des éléments de données stockés directement sur l&#39;ordinateur ou l&#39;appareil mobile que vous utilisez. Les cookies nous permettent de collecter des données telles que le type de navigateur, le temps passé sur les Services en ligne, les pages visitées, l&#39;URL de référence, les préférences linguistiques et d&#39;autres données de trafic agrégées. Nous utilisons les données à des fins de sécurité, pour faciliter la navigation, pour afficher les données plus efficacement, pour collecter des données statistiques, pour personnaliser votre expérience lors de l&#39;utilisation des Services en ligne et pour reconnaître votre ordinateur afin de faciliter votre utilisation des Services en ligne. Nous recueillons également des données statistiques sur l&#39;utilisation des Services en ligne pour améliorer continuellement la conception et la fonctionnalité, comprendre comment ils sont utilisés et nous aider à résoudre les questions.",
      "Integrate your Domains from leading providers":
        "Intégrez vos domaines des principaux fournisseurs",
      "Mint NFTs at costs starting at $1.00 (paid in HBAR)":
        "Mint NFTs à des coûts commençant à 1,00 $ (payés en HBAR)",
      "Create beautiful websites with  Smart Pages":
        "Créez de superbes sites Web avec les pages intelligentes",
      "Get access to premium Web3 Domains from Web23":
        "Accédez aux domaines Web3 premium de Web23",
      "Lowest Carbon Footprint in the entire ecosystem":
        "Empreinte carbone la plus faible de tout l'écosystème",
      "Build & grow a community and reward them with your token":
        "Construisez et développez une communauté et récompensez-la avec votre jeton",
      Continue: "Continuer",
      "Create / Import a Wallet": "Créer / Importer un portefeuille",
      "What would you like to do?": "Qu'est-ce que tu aimerais faire?",
      "Create a New Wallet or Import an Existing Wallet on the Hedera Ecosystem":
        "Créer un nouveau portefeuille ou importer un portefeuille existant sur l'écosystème Hedera",
      "Create / Manage Domains": "Créer / Gérer des domaines",
      "Manage your Domains on GoDaddy, Namecheap and other providers":
        "Gérez vos domaines sur GoDaddy, Namecheap et d'autres fournisseurs",
      "Supported Providers": "Fournisseurs pris en charge",
      "Change to": "Passer à",
      "word phrase": "expression de mot",
      "Select Domain Provider": "Sélectionnez le fournisseur de domaine",
      "Continue using": "Continuer à utiliser",
      "Copy the Keys to proceed": "Copiez les clés pour continuer",
      "To finish connecting your account, please click on “Copy and Continue” button.":
        "Pour terminer la connexion de votre compte, veuillez cliquer sur le bouton Copier et continuer.",
      Key: "Clé",
      Secret: "Secrète",
      "Copy and Continue": "Copier et continuer",
      "Do not match, please confirm and try again":
        "Ne correspond pas, veuillez confirmer et réessayer",
      "The Minimum password length is 12":
        "La longueur minimale du mot de passe est 12",
      Required: "Requis",
      "Setup a password to unlock your Web23 account":
        "Configurez un mot de passe pour déverrouiller votre compte Web23",
      "Enter Secret Phrase or Private Key":
        "Entrez la phrase secrète ou la clé privée",
      "You are on Testnet": "Vous êtes sur Testnet",
      Add: "Ajouter",
      "Valid until": "Valable jusque",
      "Expiring in": "Expirant dans",
      days: "jours",
      "Get your Domain": "Obtenez votre domaine",
      "Your Hedera ID": "Votre identifiant Hedera",
      unknown: "inconnue",
      "Your QR Code": "Votre code QR",
      "Copied to clipboard": "Copié dans le presse-papier",
      "Deposit HBAR": "Dépôt HBAR",
      "Buy HBAR using Transak": "",
      "Buy using Banxa or Moonpay": "",
      "Associating or Dissociating with your wallet costs $0.05. Do you want to continue?":
        "",
      Confirm: "",
      "Association or Dissociation is succeeded.": "",
      "Association or Dissociation is failed.": "",
      "Your wallets": "",
      "Successfully saved": "",
      "Successfully deleted": "",
      "Buy Assets": "",
      "Associate Token": "",
      "Swap Tokens": "",
      "Swap from": "",
      "Enter amount": "",
      "SWAP ALL": "",
      Balance: "",
      "Swap to": "",
      "Choose Token": "",
      "Current Balance": "",
      "Max Slippage": "",
      "Transaction Fees": "",
      "Review Swap": "",
      "Search Currency": "",
      "Your transaction will not go through if the price changes more than this percentage":
        "",
      "Swap Initiated": "",
      "Your transaction is initiated and will go through in a few minutes. We shall keep you updated.":
        "",
      Review: "",
      "Choose token to send": "",
      "Edit Contact": "",
      "Enter name": "",
      "Review Transaction": "",
      "Add Memo(optional)": "",
      "Confirm transaction": "",
      "Invalid operation": "",
      "Transaction Initiated": "",
      "Kindly complete the transaction in Banxa window": "",
      "Pay using": "",
      "You'll receive": "",
      "Order Initiated": "",
      "wants to connect with your wallet": "",
      "will be able to": "",
      "Choose the wallet to connect with": "",
      "is connected to your wallet": "",
      "Are you sure you want to remove connection with": "",
      "Email us": "",
      Discord: "",
      Twitter: "",
    },
  },
};

export { french };
