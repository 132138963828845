const mandarin = {
  mandarin: {
    translation: {
      "Create a new Wallet": "创建一个新钱包",
      "Import an existing wallet": "导入现有钱包",
      "Secret Recovery Phrase": "秘密助记词",
      "Please write them down or store it somewhere safe.":
        "请将它们写下来或存放在安全的地方。",
      "Copy to clipboard": "复制到剪贴板",
      "I have securely saved it": "我已经安全地保存了它",
      "Copied secret phrase": "复制密语",
      "Create Password": "创建密码",
      "Setup a password to unlock your super wallet":
        "设置密码以解锁您的超级钱包",
      "Enter password": "输入密码",
      "Confirm password": "确认密码",
      "I agree with the terms and Conditions": "我同意条款和条件",
      "I agree with the": "我同意",
      "terms and Conditions": "条款和条件",
      Proceed: "继续",
      "You are all set!": "一切就绪！",
      "Congratulations!": "恭喜！",
      "Your Hedera super wallet is ready to go":
        "您的 Hedera 超级钱包已准备就绪",
      "Let’s see my dashboard": "让我们看看我的仪表板",
      "Enter 12, 24 word secret recovery phrase or private key to import your existing wallet":
        "输入 12、24 字的秘密恢复短语或私钥以导入您现有的钱包",
      "Enter Secret Phase": "进入秘密阶段",
      "WALLET VALUE": "钱包价值",
      Send: "发送",
      Purchase: "购买",
      Receive: "收到",
      Swap: "交换",
      "My Domains": "我的域名",
      All: "全部",
      "Manage Domains": "管理域",
      "200+ extensions available": "提供 200 多个扩展",
      "Get a Domain": "获取域名",
      "Available Tokens": "可用代币",
      "Manage Tokens": "管理代币",
      "My wallets": "我的钱包",
      "Create new wallet": "创建新钱包",
      Wallets: "钱包",
      Mainnet: "主网",
      "All your real transactions here": "您所有的真实交易都在这里",
      Testnet: "测试网",
      "Used for testing only": "仅用于测试",
      "Edit Wallet": "编辑钱包",
      Delete: "删除",
      "Wallet name": "钱包名称",
      "Wallet Customization": "钱包定制",
      Initial: "最初的",
      Character: "特点",
      Icon: "图标",
      "Wallet Icon": "钱包图标",
      "Save changes": "保存更改",
      "Connected Site": "连接站点",
      "All your Domains here": "您所有的域名都在这里",
      "Manage & Track all your domains through your Web23 wallet":
        "通过您的 Web23 钱包管理和跟踪您的所有域",
      "Get Started": "开始",
      "Nothing to see here": "这没东西看",
      "Add / Transfer / Get your new Domain today":
        "立即添加/转移/获取您的新域",
      "Search by name or address": "按名称或地址搜索",
      PASTE: "粘贴",
      "Enabled Tokens": "启用令牌",
      "Associating a token with your wallet costs $0.05":
        "将代币与您的钱包关联需要 0.05 美元",
      "Search results for": "为。。。。寻找结果",
      Settings: "设置",
      Preferences: "喜好",
      "Expand View": "展开视图",
      Language: "语言",
      Currency: "货币",
      Network: "网络",
      Theme: "主题",
      "Secret Phrase": "密语",
      "Connected Sites": "连接的站点",
      "Lock Wallet": "锁定钱包",
      "Terms and Privacy": "条款和隐私",
      "Contact Support": "联系支持",
      "United States Dollar": "美国美元",
      Euro: "欧元",
      "British Pound": "英镑",
      "Indian Rupee": "印度卢比",
      "Australian Dollar": "澳元",
      "Canadian Dollar": "加拿大元",
      "Chinese Yuan": "人民币",
      "Turkish Lira": "土耳其里拉",
      English: "英语",
      Español: "西班牙语",
      Italiano: "意大利语",
      Polish: "抛光",
      Deutsch: "德语",
      French: "法语",
      Hindi: "没有",
      Arabic: "阿拉伯",
      Mandarin: "普通话",
      Japanese: "日本人",
      System: "系统",
      Light: "光",
      Dark: "黑暗的",
      "Hover to view the phrase": "悬停以查看短语",
      "These words are the keys to your wallet": "这些话是你钱包的钥匙",
      "Welcome back": "欢迎回来",
      "Manage all your domains from one wallet": "从一个钱包管理您的所有域",
      Unlock: "开锁",
      "Forgot Password?": "忘记密码？",
      "Superrare will be able to": "Superrare 将能够",
      "Connect with Web23": "连接 Web23",
      "Connect your wallet to dApps to get started":
        "将您的钱包连接到 dApp 以开始使用",
      "No sites connected": "没有网站连接",
      "Wallet 1 isn’t connected to any sites": "钱包 1 未连接到任何站点",
      "Manually Connect": "手动连接",
      "Connecting to this wallet": "连接到这个钱包",
      "Access your wallet address, activity and balance":
        "访问您的钱包地址、活动和余额",
      "Request approval for transactions": "请求批准交易",
      Connect: "连接",
      "Switch Wallet": "切换钱包",
      Connecting: "连接中",
      "Connection successful": "连接成功",
      "Connection Details": "连接细节",
      "Remove Connection": "删除连接",
      "Remove Connection?": "删除连接？",
      "Are you sure you want to remove connection with Superrare?":
        "您确定要删除与 Superrare 的连接吗？",
      Cancel: "取消",
      Remove: "消除",
      "Privacy Statement": "隐私声明",
      "This Privacy Statement describes the privacy practices of Unlabelled for data that we collect: Through the software applications made available by us for use on or through computers and mobile devices (the “Apps”). Through our social media pages that we control from which you are accessing this Privacy Statement (collectively, our “Social Media Pages”). Through HTML-formatted email messages that we send you that link to this Privacy Statement and through your communications with us. Collectively, we refer to the Websites, the Apps and our Social Media Pages, as the “Online Services” and, together with offline channels, the “Services.” By using the Services, you agree to the terms and conditions of this Privacy Statement.":
        "本隐私声明描述了 Unlabelled 对于我们收集的数据的隐私惯例：通过我们提供的软件应用程序，供在计算机和移动设备上使用或通过计算机和移动设备（“应用程序”）使用。通过我们控制的社交媒体页面，您可以从中访问本隐私声明（统称为我们的“社交媒体页面”）。通过我们向您发送的包含本隐私声明链接的 HTML 格式的电子邮件以及您与我们的通信。我们将网站、应用程序和我们的社交媒体页面统称为“在线服务”，并与线下渠道一起称为“服务”。使用服务即表示您同意本隐私声明的条款和条件。",
      "“Personal Data” are data that identify you as an individual or relate to an identifiable individual. At touch points throughout your guest journey, we sometimes collect Personal Data such as: Email address":
        "“个人数据”是将您识别为个人或与可识别个人相关的数据。在您的客人旅程中的接触点，我们有时会收集个人数据，例如：电子邮件地址",
      "We collect certain data from cookies, which are pieces of data stored directly on the computer or mobile device that you are using. Cookies allow us to collect data such as browser type, time spent on the Online Services, pages visited, referring URL, language preferences, and other aggregated traffic data. We use the data for security purposes, to facilitate navigation, to display data more effectively, to collect statistical data, to personalize your experience while using the Online Services and to recognize your computer to assist your use of the Online Services. We also gather statistical data about use of the Online Services to continually improve design and functionality, understand how they are used and assist us with resolving questions.":
        "我们从 cookie 中收集某些数据，这些数据直接存储在您使用的计算机或移动设备上。 Cookie 使我们能够收集浏览器类型、花在在线服务上的时间、访问的页面、引用 URL、语言偏好和其他聚合流量数据等数据。我们将数据用于安全目的，以方便导航、更有效地显示数据、收集统计数据、在使用在线服务时个性化您的体验，以及识别您的计算机以协助您使用在线服务。我们还收集有关使用在线服务的统计数据，以不断改进设计和功能，了解它们的使用方式并帮助我们解决问题。",
      "Integrate your Domains from leading providers": "整合领先供应商的域名",
      "Mint NFTs at costs starting at $1.00 (paid in HBAR)":
        "成本为 1.00 美元的 Mint NFT（以 HBAR 支付",
      "Create beautiful websites with  Smart Pages":
        "使用智能页面创建漂亮的网站",
      "Get access to premium Web3 Domains from Web23":
        "从 Web23 获取高级 Web3 域",
      "Lowest Carbon Footprint in the entire ecosystem":
        "整个生态系统中最低的碳足迹",
      "Build & grow a community and reward them with your token":
        "建立和发展社区并用您的代币奖励他们",
      Continue: "继续",
      "Create / Import a Wallet": "创建/导入钱包",
      "What would you like to do?": "你想干什么？",
      "Create a New Wallet or Import an Existing Wallet on the Hedera Ecosystem":
        "在 Hedera 生态系统上创建新钱包或导入现有钱包",
      "Create / Manage Domains": "创建/管理域",
      "Manage your Domains on GoDaddy, Namecheap and other providers":
        "在 GoDaddy、Namecheap 和其他提供商上管理您的域",
      "Supported Providers": "支持的供应商",
      "Change to": "改成",
      "word phrase": "词组",
      "Select Domain Provider": "选择域名提供商",
      "Continue using": "继续使用",
      "Copy the Keys to proceed": "复制密钥以继续",
      "To finish connecting your account, please click on “Copy and Continue” button.":
        "要完成连接您的帐户，请单击“复制并继续”按钮。",
      Key: "钥匙",
      Secret: "秘密",
      "Copy and Continue": "复制并继续",
      "Do not match, please confirm and try again": "不匹配，请确认后重试",
      "The Minimum password length is 12": "最小密码长度为 12",
      Required: "必需的",
      "Setup a password to unlock your Web23 account":
        "设置密码以解锁您的 Web23 帐户",
      "Enter Secret Phrase or Private Key": "输入密码或私钥",
      "You are on Testnet": "你在测试网上",
      Add: "添加",
      "Valid until": "有效期至",
      "Expiring in": "到期时间",
      days: "天",
      "Get your Domain": "获取您的域名",
      "Your Hedera ID": "您的常春藤 ID",
      unknown: "未知",
      "Your QR Code": "您的二维码",
      "Copied to clipboard": "复制到剪贴板",
      "Deposit HBAR": "存入HBAR",
      "Buy HBAR using Transak": "",
      "Buy using Banxa or Moonpay": "",
      "Associating or Dissociating with your wallet costs $0.05. Do you want to continue?":
        "",
      Confirm: "",
      "Association or Dissociation is succeeded.": "",
      "Association or Dissociation is failed.": "",
      "Your wallets": "",
      "Successfully saved": "",
      "Successfully deleted": "",
      "Buy Assets": "",
      "Associate Token": "",
      "Swap Tokens": "",
      "Swap from": "",
      "Enter amount": "",
      "SWAP ALL": "",
      Balance: "",
      "Swap to": "",
      "Choose Token": "",
      "Current Balance": "",
      "Max Slippage": "",
      "Transaction Fees": "",
      "Review Swap": "",
      "Search Currency": "",
      "Your transaction will not go through if the price changes more than this percentage":
        "",
      "Swap Initiated": "",
      "Your transaction is initiated and will go through in a few minutes. We shall keep you updated.":
        "",
      Review: "",
      "Choose token to send": "",
      "Edit Contact": "",
      "Enter name": "",
      "Review Transaction": "",
      "Add Memo(optional)": "",
      "Confirm transaction": "",
      "Invalid operation": "",
      "Transaction Initiated": "",
      "Kindly complete the transaction in Banxa window": "",
      "Pay using": "",
      "You'll receive": "",
      "Order Initiated": "",
      "wants to connect with your wallet": "",
      "will be able to": "",
      "Choose the wallet to connect with": "",
      "is connected to your wallet": "",
      "Are you sure you want to remove connection with": "",
      "Email us": "",
      Discord: "",
      Twitter: "",

      New: "",
      "Per token": "",
      Coupons: "",
      "Fans can claim 1 free token": "",
      Donations: "",
      "Fans can donate you any amount": "",
      "Top-Up": "",
      Gift: "",
      "Setup your token and build your own economy in Web23": "",
      "Create your unique token and reward your fans and followers with them":
        "",
      "Setup Social Tokens": "",
      "Manage Contacts": "",
      "Pasted Wallet Address": "",
      SAVE: "",
      "Frequently interacted with": "",
      "Search results in wallets for": "",
      "All Contacts": "",
      "Enter wallet password to reveal the private key": "",
      "This is the key to your wallet": "",
      "Please make sure no one is watching your screen": "",
      "Enter wallet password to reveal the secret phrase": "",
      General: "",
      Protection: "",
      "Private Key": "",
      "About Web23": "",
      "App Version": "",
      "Collection of Personal Data": "",
      "Create NFT": "",
      "Upload File": "",
      Uploading: "",
      "Click to upload a file": "",
      "File types supported": "",
      "Re-upload file": "",
      "Basic Details": "",
      "Enter NFT Title": "",
      "Choose Supply": "",
      "Choose Wallet": "",
      "Additional Details": "",
      Collection: "",
      "External Link": "",
      "Not added yet": "",
      Attributes: "",
      "Add attributes and their respective percentages": "",
      "Add Attributes": "",
      "Enter Collection Name": "",
      Name: "",
      Value: "",
      "Add another": "",
      "Review NFT Creation": "",
      Creating: "",
      "Add Memo (optional)": "",
      "Confirm NFT creation": "",
      "Last Sale Price": "",
      "Floor Value": "",
      Category: "",
      "Read more": "",
      Description: "",
      Activity: "",
      "total floor value": "",
      "Past 24 hours": "",
      Create: "",
      Your: "",
      Sort: "",
      "Sort by A-Z": "",
      "Sort by Z-A": "",
      "Recently Added": "",
      "Price: Low to High": "",
      "Price: High to Low": "",
      Apply: "",
      Contacts: "",
      Sending: "",
      "Auto Renews": "",
      Status: "",
      Thumbnail: "",
      Added: "",
      "Update where the domain redirects to": "",
      "Update DNS settings": "",
      Whois: "",
      "Update contact info": "",
      "Renew automatically when expiring": "",
      "Transfer Lock": "",
      "Lock domain from being transferred": "",
      "Domain Privacy": "",
      "Protect your contact information": "",
      "Shared with": "",
      "Manage Permissions": "",
      "Connected Account": "",
      Update: "",
      Domains: "",
      "Find your place online": "",
      "Get started with your idea from more than 200 domain endings": "",
      "Search for your domain": "",
      "Transparent Pricing": "",
      "Industry top security": "",
      "Starting at": "",
      "More reasons to buy a Domain on Web23": "",
      "Transfer a Domain you own": "",
      "Connected Accounts": "",
      "Manage Accounts": "",
      "Domain Search Result": "",
      Available: "",
      Price: "",
      "You can now setup your smart page": "",
      "Post NFTs, photos, videos, articles & more": "",
      "Build a dedicated community and reward your best followers with unique social coins":
        "",
      "Monentize your artwork and content on the go and get paid to your web2 wallet":
        "",
      "Continue to my Smart page": "",
      "Smart Pages": "",
      "Smart Pages Setup Incomplete": "",
      "Web3 domains starting at": "",
      "To get access to the Web3 domains, activate your Hedera wallet": "",
      "Activate Hedera wallet": "",
      "Create new smart page": "",
      "Link an existing smart page": "",
      "Login Web23 SmartPage": "",
      "Sign In with your Email, Web3 domain and password": "",
      Email: "",
      Domain: "",
      "Login password": "",
      "New to Web23 SmartPage?": "",
      "Create an Account": "",
      "Create Smart Page": "",
      "Let’s setup some basic details": "",
      "Display name": "",
      "Email address": "",
      "Web3 domain": "",
      "or continue with": "",
      "Continue with": "",
      "Choose one of the options": "",
      "Enter verification code": "",
      "2-Step Verification": "",
      "Please enter the 6-digit verifiction code sent to": "",
      "unknown email": "",
      "Enter code": "",
      "Didn’t receive the code yet?": "",
      "Resend code": "",
      "Verify code": "",
      "NFTs that are posted already have a grey overlay": "",
      "Let’s add an intro here": "",
      Timeline: "",
      Market: "",
      Tokens: "",
      "Copy Profile URL": "",
      "Open on Desktop": "",
      "Edit Profile": "",
      "Edit Cover Photo": "",
      "Edit Profile Picture": "",
      "Edit Location": "",
      "Edit Intro": "",
      "Manage Links": "",
      "Edit Profile Details": "",
      "Profile Details": "",
      "Enter Location": "",
      "Save Profile": "",
      "Social Networks": "",
      "add here": "",
      "Add Custom Link": "",
      "Add here": "",
      "Add another link": "",
      Post: "",
      "Add from wallet or Mint New": "",
      Photo: "",
      Audio: "",
      Video: "",
      Article: "",
      "Add from Wallet": "",
      "Create New": "",
      "Only for $0.5 in HBAR fees": "",
      "Fans can claim a free token": "",
      "Token Discount": "",
      "Fans can’t claim free tokens": "",
      "Fans can donate": "",
      "Fans can’t donate": "",
      "Top-up balance": "",
      "How much do you want to top-up?": "",
      Supply: "",
      "Continue to Review": "",
      "Total Value": "",
      "Token name should use 4-6 characters and should be alphanumeric only":
        "",
      "What are Social Tokens?": "",
      "Your tokens, once launched can be used by your fans to unlock your content & experiences":
        "",
      "Coupon Giveaways": "",
      "Giveaway the coupons to purchase your art at a discount": "",
      "Let fans Say Thanks": "",
      "Receive donations from your fans": "",
      "10 minute Video Call": "",
      "Let your fans can setup a 1:1 live video call with you": "",
      "Subscription to your club": "",
      "Sell recurring access to your content and experiences": "",
      "You have launched your unique token. You can find the following token card on your smart page dashboard.":
        "",
      "Go to Dashboard!": "",
      "Max size": "",
      "Tell us more about it": "",
      "Alternate Text": "",
      "Fixed Price": "",
      "Open for Bids": "",
      "Not for Sale": "",
      "Fixed Price details": "",
      Royalty: "",
      "Auction details": "",
      "Min bid price": "",
      "Auction start time (IST)": "",
      "Auction end time (IST)": "",
      Unique: "",
      Single: "",
      Multiple: "",
      "Multiple copies": "",
      "Creating and Posting": "",
      "Marketplace Listing": "",
    },
  },
};

export { mandarin };
