import React, { useState, useContext, useEffect } from "react";
import { goTo } from "react-chrome-extension-router";
import axios from "axios";
import { useTranslation } from "react-i18next";

import DashboardCompass from ".";
import SmartSetupPage from "src/pages/smart/setup";

import { PageContainer } from "src/layout";

import {
  Web23Button,
  Web23Input,
  DashboardActionBar,
  Web23Popup,
} from "src/components";

import getSelectedUser from "src/utility/getSelectedUser";
import apiHandler from "src/utility/apiHandler";

import { API_SMART_ENDPOINT_URL } from "src/config/index";
import { SettingContext } from "src/utility/context";

import { ReactComponent as PageSVG } from "src/assets/icons/pages_sm.svg";
import { ReactComponent as ArrowDownSVG } from "src/assets/icons/arrow-down.svg";
import { ReactComponent as ArrowSVG } from "src/assets/icons/arrow-down.svg";

const LoginSmartPage: React.FC = () => {
  const { t } = useTranslation();
return(    
<>
      <PageContainer>
        <div className="h-full flex flex-col justify-between px-0">
          <div className="px-3 flex flex-col justify-between h-full">
            <div>
              <div>
                <h3 className="text-xl font-bold cursor-pointer text-grey-50 px-6 py-5 text-center">
                  {t("News of  Web23")}
                </h3>
              </div>
              <div className="mt-4">
                <div className="flex justify-center mb-4">
                  <div className="w-[48px] h-[48px] rounded-full bg-grey-900 flex justify-center items-center">
                    <PageSVG />
                  </div>
                </div>
                <p className="mb-6 text-sm font-bold text-center text-grey-200">
                  {t("No news and updates found.")}
                </p>
              </div>
	</div>
	</div>
	<DashboardActionBar selected={2} />
	</div>

	</PageContainer>
    </>
  );
};

export default LoginSmartPage;
