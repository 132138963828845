const jp = {
  japanese: {
    translation: {
      "Create a new Wallet": "新しいウォレットを作成する",
      "Import an existing wallet": "既存のウォレットをインポートする",
      "Secret Recovery Phrase": "秘密の回復フレーズ",
      "Please write them down or store it somewhere safe.":
        "それらを書き留めるか、安全な場所に保管してください。",
      "Copy to clipboard": "クリップボードにコピー",
      "I have securely saved it": "しっかり保存しました",
      "Copied secret phrase": "コピーした合言葉",
      "Create Password": "パスワードの作成",
      "Setup a password to unlock your super wallet":
        "スーパーウォレットのロックを解除するためのパスワードを設定します",
      "Enter password": "パスワードを入力する",
      "Confirm password": "パスワードを認証する",
      "I agree with the terms and Conditions": "利用規約に同意します",
      "I agree with the": "私は同意します",
      "terms and Conditions": "規約と条件",
      Proceed: "続行",
      "You are all set!": "準備万端です！",
      "Congratulations!": "おめでとう！",
      "Your Hedera super wallet is ready to go":
        "あなたのヘデラスーパーウォレットは準備ができています",
      "Let’s see my dashboard": "ダッシュボードを見てみましょう",
      "Enter 12, 24 word secret recovery phrase or private key to import your existing wallet":
        "12、24 ワードの秘密の復元フレーズまたは秘密鍵を入力して、既存のウォレットをインポートします",
      "Enter Secret Phase": "シークレットフェーズに入る",
      "WALLET VALUE": "財布の価値",
      Send: "送信",
      Purchase: "購入",
      Receive: "受け取る",
      Swap: "スワップ",
      "My Domains": "私のドメイン",
      All: "全て",
      "Manage Domains": "ドメインの管理",
      "200+ extensions available": "200以上の拡張機能が利用可能",
      "Get a Domain": "ドメインを取得する",
      "Available Tokens": "利用可能なトークン",
      "Manage Tokens": "トークンの管理",
      "My wallets": "私の財布",
      "Create new wallet": "新しいウォレットを作成する",
      Wallets: "財布",
      Mainnet: "メインネット",
      "All your real transactions here": "すべての実際の取引はこちら",
      Testnet: "テストネット",
      "Used for testing only": "テストのみに使用",
      "Edit Wallet": "ウォレットの編集",
      Delete: "消去",
      "Wallet name": "ウォレット名",
      "Wallet Customization": "ウォレットのカスタマイズ",
      Initial: "イニシャル",
      Character: "キャラクター",
      Icon: "アイコン",
      "Wallet Icon": "財布のアイコン",
      "Save changes": "変更内容を保存",
      "Connected Site": "接続されたサイト",
      "All your Domains here": "すべてのドメインがここに",
      "Manage & Track all your domains through your Web23 wallet":
        "Web23 ウォレットを介してすべてのドメインを管理および追跡します",
      "Get Started": "始めましょう",
      "Nothing to see here": "ここには何も表示されません",
      "Add / Transfer / Get your new Domain today":
        "新しいドメインを今すぐ追加/移管/取得",
      "Search by name or address": "名前または住所で検索",
      PASTE: "ペースト",
      "Enabled Tokens": "有効なトークン",
      "Associating a token with your wallet costs $0.05":
        "トークンをウォレットに関連付けるには 0.05 ドルかかります",
      "Search results for": "の検索結果",
      Settings: "設定",
      Preferences: "環境設定",
      "Expand View": "ビューを展開",
      Language: "言語",
      Currency: "通貨",
      Network: "通信網",
      Theme: "テーマ",
      "Secret Phrase": "秘密のフレーズ",
      "Connected Sites": "接続されたサイト",
      "Lock Wallet": "ウォレットをロック",
      "Terms and Privacy": "規約とプライバシー",
      "Contact Support": "サポート問い合わせ先",
      "United States Dollar": "米ドル",
      Euro: "ユーロ",
      "British Pound": "英国ポンド",
      "Indian Rupee": "インドルピー",
      "Australian Dollar": "オーストラリアドル",
      "Canadian Dollar": "カナダドル",
      "Chinese Yuan": "中国人民元",
      "Turkish Lira": "トルコリラ",
      English: "英語",
      Español: "スペイン語",
      Italiano: "イタリア語",
      Polish: "研磨",
      Deutsch: "ドイツ人",
      French: "フランス語",
      Hindi: "いいえ",
      Arabic: "アラビア語",
      Mandarin: "マンダリン",
      Japanese: "日本",
      System: "システム",
      Light: "光",
      Dark: "暗い",
      "Hover to view the phrase": "カーソルを合わせるとフレーズが表示されます",
      "These words are the keys to your wallet":
        "これらの言葉はあなたの財布の鍵です",
      "Welcome back": "おかえり",
      "Manage all your domains from one wallet":
        "1 つのウォレットからすべてのドメインを管理",
      Unlock: "ロック解除",
      "Forgot Password?": "パスワードをお忘れですか？",
      "Superrare will be able to": "スーパーレアでできること",
      "Connect with Web23": "Web23に接続する",
      "Connect your wallet to dApps to get started":
        "ウォレットを dApps に接続して開始します",
      "No sites connected": "接続されているサイトがありません",
      "Wallet 1 isn’t connected to any sites":
        "ウォレット 1 はどのサイトにも接続されていません",
      "Manually Connect": "手動で接続",
      "Connecting to this wallet": "このウォレットに接続中",
      "Access your wallet address, activity and balance":
        "ウォレットのアドレス、アクティビティ、残高にアクセスする",
      "Request approval for transactions": "取引承認申請",
      Connect: "接続",
      "Switch Wallet": "ウォレットの切り替え",
      Connecting: "接続中",
      "Connection successful": "接続成功",
      "Connection Details": "接続の詳細",
      "Remove Connection": "接続を削除",
      "Remove Connection?": "接続を削除しますか?",
      "Are you sure you want to remove connection with Superrare?":
        "Superrare との接続を削除してもよろしいですか?",
      Cancel: "キャンセル",
      Remove: "削除する",
      "Privacy Statement": "プライバシーに関する声明",
      "This Privacy Statement describes the privacy practices of Unlabelled for data that we collect: Through the software applications made available by us for use on or through computers and mobile devices (the “Apps”). Through our social media pages that we control from which you are accessing this Privacy Statement (collectively, our “Social Media Pages”). Through HTML-formatted email messages that we send you that link to this Privacy Statement and through your communications with us. Collectively, we refer to the Websites, the Apps and our Social Media Pages, as the “Online Services” and, together with offline channels, the “Services.” By using the Services, you agree to the terms and conditions of this Privacy Statement.":
        "このプライバシーに関する声明では、収集するデータに関する Unlabelled のプライバシー慣行について説明します。このプライバシーに関する声明にアクセスしている、当社が管理する当社のソーシャル メディア ページ (まとめて「当社のソーシャル メディア ページ」) を通じて。このプライバシーに関する声明へのリンクを含む HTML 形式の電子メール メッセージを送信し、お客様と当社との通信を通じて。ウェブサイト、アプリ、ソーシャル メディア ページをまとめて「オンライン サービス」と呼び、オフライン チャネルと合わせて「サービス」と呼びます。サービスを使用することにより、このプライバシーに関する声明の条件に同意したことになります。",
      "“Personal Data” are data that identify you as an individual or relate to an identifiable individual. At touch points throughout your guest journey, we sometimes collect Personal Data such as: Email address":
        "「個人データ」とは、お客様を個人として識別するデータ、または識別可能な個人に関連するデータです。ゲストの旅の接点で、次のような個人データを収集することがあります。",
      "We collect certain data from cookies, which are pieces of data stored directly on the computer or mobile device that you are using. Cookies allow us to collect data such as browser type, time spent on the Online Services, pages visited, referring URL, language preferences, and other aggregated traffic data. We use the data for security purposes, to facilitate navigation, to display data more effectively, to collect statistical data, to personalize your experience while using the Online Services and to recognize your computer to assist your use of the Online Services. We also gather statistical data about use of the Online Services to continually improve design and functionality, understand how they are used and assist us with resolving questions.":
        "Cookie から特定のデータを収集します。Cookie は、使用しているコンピューターまたはモバイル デバイスに直接保存されるデータの一部です。 Cookie を使用すると、ブラウザーの種類、オンライン サービスの利用時間、アクセスしたページ、参照 URL、言語設定、およびその他の集約されたトラフィック データなどのデータを収集できます。当社は、セキュリティの目的でデータを使用し、ナビゲーションを容易にし、データをより効果的に表示し、統計データを収集し、オンライン サービスを使用する際のエクスペリエンスをパーソナライズし、コンピューターを認識してオンライン サービスの使用を支援します。また、デザインと機能を継続的に改善し、それらがどのように使用されているかを理解し、質問の解決に役立てるために、オンライン サービスの使用に関する統計データを収集します。",
      "Integrate your Domains from leading providers":
        "主要プロバイダーのドメインを統合",
      "Mint NFTs at costs starting at $1.00 (paid in HBAR)":
        "$1.00 からの NFT のミント (HBAR で支払われる)",
      "Create beautiful websites with  Smart Pages":
        "スマート ページで美しい Web サイトを作成する",
      "Get access to premium Web3 Domains from Web23":
        "Web23 からプレミアム Web3 ドメインにアクセスする",
      "Lowest Carbon Footprint in the entire ecosystem":
        "エコシステム全体で最も低いカーボンフットプリント",
      "Build & grow a community and reward them with your token":
        "コミュニティを構築して成長させ、トークンで報酬を与える",
      Continue: "続く",
      "Create / Import a Wallet": "ウォレットの作成/インポート",
      "What would you like to do?": "何をしたいですか？",
      "Create a New Wallet or Import an Existing Wallet on the Hedera Ecosystem":
        "Hedera エコシステムで新しいウォレットを作成するか、既存のウォレットをインポートする",
      "Create / Manage Domains": "ドメインの作成/管理",
      "Manage your Domains on GoDaddy, Namecheap and other providers":
        "GoDaddy、Namecheap、その他のプロバイダーでドメインを管理する",
      "Supported Providers": "サポートされているプロバイダー",
      "Change to": "への変更",
      "word phrase": "語句",
      "Select Domain Provider": "ドメイン プロバイダーの選択",
      "Continue using": "使い続ける",
      "Copy the Keys to proceed": "キーをコピーして続行します",
      "To finish connecting your account, please click on “Copy and Continue” button.":
        "アカウントの接続を完了するには、[コピーして続行] ボタンをクリックしてください。",
      Key: "鍵",
      Secret: "ひみつ",
      "Copy and Continue": "コピーして続行",
      "Do not match, please confirm and try again":
        "一致しません。確認してからもう一度お試しください",
      "The Minimum password length is 12": "最小パスワード長は 12 です",
      Required: "必要",
      "Setup a password to unlock your Web23 account":
        "パスワードを設定して Web23 アカウントのロックを解除します",
      "Enter Secret Phrase or Private Key":
        "秘密のフレーズまたは秘密鍵を入力してください",
      "You are on Testnet": "あなたはテストネットにいます",
      Add: "追加",
      "Valid until": "まで有効",
      "Expiring in": "期限切れ",
      days: "日々",
      "Get your Domain": "ドメインを取得する",
      "Your Hedera ID": "あなたのヘデラID",
      unknown: "知らない",
      "Your QR Code": "あなたのQRコード",
      "Copied to clipboard": "クリップボードにコピーしました",
      "Deposit HBAR": "HBARの入金",
      "Buy HBAR using Transak": "",
      "Buy using Banxa or Moonpay": "",
      "Associating or Dissociating with your wallet costs $0.05. Do you want to continue?":
        "",
      Confirm: "",
      "Association or Dissociation is succeeded.": "",
      "Association or Dissociation is failed.": "",
      "Your wallets": "",
      "Successfully saved": "",
      "Successfully deleted": "",
      "Buy Assets": "",
      "Associate Token": "",
      "Swap Tokens": "",
      "Swap from": "",
      "Enter amount": "",
      "SWAP ALL": "",
      Balance: "",
      "Swap to": "",
      "Choose Token": "",
      "Current Balance": "",
      "Max Slippage": "",
      "Transaction Fees": "",
      "Review Swap": "",
      "Search Currency": "",
      "Your transaction will not go through if the price changes more than this percentage":
        "",
      "Swap Initiated": "",
      "Your transaction is initiated and will go through in a few minutes. We shall keep you updated.":
        "",
      Review: "",
      "Choose token to send": "",
      "Edit Contact": "",
      "Enter name": "",
      "Review Transaction": "",
      "Add Memo(optional)": "",
      "Confirm transaction": "",
      "Invalid operation": "",
      "Transaction Initiated": "",
      "Kindly complete the transaction in Banxa window": "",
      "Pay using": "",
      "You'll receive": "",
      "Order Initiated": "",
      "wants to connect with your wallet": "",
      "will be able to": "",
      "Choose the wallet to connect with": "",
      "is connected to your wallet": "",
      "Are you sure you want to remove connection with": "",
      "Email us": "",
      Discord: "",
      Twitter: "",
    },
  },
};
export { jp };
