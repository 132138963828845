const de = {
  de: {
    translation: {
      "Create a new Wallet": "Erstellen Sie eine neue Brieftasche",
      "Import an existing wallet":
        "Importieren Sie eine vorhandene Brieftasche",
      "Secret Recovery Phrase": "Geheime Wiederherstellungsphrase",
      "Please write them down or store it somewhere safe.":
        "Bitte notieren Sie diese oder bewahren Sie sie an einem sicheren Ort auf.",
      "Copy to clipboard": "In die Zwischenablage kopieren",
      "I have securely saved it": "Ich habe es sicher gespeichert",
      "Copied secret phrase": "Kopierte Geheimphrase",
      "Create Password": "Passwort erstellen",
      "Setup a password to unlock your super wallet":
        "Richten Sie ein Passwort ein, um Ihre Super Wallet zu entsperren",
      "Enter password": "Passwort eingeben",
      "Confirm password": "Kennwort bestätigen",
      "I agree with the terms and Conditions":
        "Ich stimme den Nutzungsbedingungen zu",
      "I agree with the": "Ich stimme dem zu",
      "terms and Conditions": "Geschäftsbedingungen",
      Proceed: "Fortfahren",
      "You are all set!": "Sie sind bereit!",
      "Congratulations!": "Glückwünsche!",
      "Your Hedera super wallet is ready to go":
        "Ihre Hedera Super Wallet ist einsatzbereit",
      "Let’s see my dashboard": "Sehen wir uns mein Dashboard an",
      "Enter 12, 24 word secret recovery phrase or private key to import your existing wallet":
        "Geben Sie einen geheimen Wiederherstellungssatz mit 12, 24 Wörtern oder einen privaten Schlüssel ein, um Ihre vorhandene Brieftasche zu importieren",
      "Enter Secret Phase": "Geben Sie die geheime Phase ein",
      "WALLET VALUE": "GELDBÖRSE WERT",
      Send: "Senden",
      Purchase: "Kaufen",
      Receive: "Erhalten",
      Swap: "Tausch",
      "My Domains": "Meine Domänen",
      All: "Alle",
      "Manage Domains": "Domänen verwalten",
      "200+ extensions available": "Über 200 Erweiterungen verfügbar",
      "Get a Domain": "Holen Sie sich eine Domäne",
      "Available Tokens": "Verfügbare Token",
      "Manage Tokens": "Token verwalten",
      "My wallets": "Meine Brieftaschen",
      "Create new wallet": "Erstellen Sie eine neue Brieftasche",
      Wallets: "Geldbörsen",
      Mainnet: "Hauptnetz",
      "All your real transactions here": "Alle Ihre echten Transaktionen hier",
      Testnet: "Testnet",
      "Used for testing only": "Wird nur zum Testen verwendet",
      "Edit Wallet": "Geldbörse bearbeiten",
      Delete: "Löschen",
      "Wallet name": "Wallet-Name",
      "Wallet Customization": "Wallet-Anpassung",
      Initial: "Initial",
      Character: "Charakter",
      Icon: "Symbol",
      "Wallet Icon": "Wallet-Symbol",
      "Save changes": "Änderungen speichern",
      "Connected Site": "Verbundene Website",
      "All your Domains here": "Alle Ihre Domains hier",
      "Manage & Track all your domains through your Web23 wallet":
        "Verwalten und verfolgen Sie alle Ihre Domains über Ihr Web23-Wallet",
      "Get Started": "Loslegen",
      "Nothing to see here": "Es gibt hier nichts zu sehen",
      "Add / Transfer / Get your new Domain today":
        "Fügen Sie Ihre neue Domain hinzu / übertragen Sie sie / erhalten Sie sie noch heute",
      "Search by name or address": "Suche nach Name oder Adresse",
      PASTE: "EINFÜGEN",
      "Enabled Tokens": "Aktivierte Token",
      "Associating a token with your wallet costs $0.05":
        "Die Verknüpfung eines Tokens mit Ihrer Brieftasche kostet 0,05 $",
      "Search results for": "Suchergebnisse für",
      Settings: "Einstellungen",
      Preferences: "Einstellungen",
      "Expand View": "Ansicht erweitern",
      Language: "Sprache",
      Currency: "Währung",
      Network: "Netzwerk",
      Theme: "Thema",
      "Secret Phrase": "Geheime Phrase",
      "Connected Sites": "Verbundene Websites",
      "Lock Wallet": "Brieftasche sperren",
      "Terms and Privacy":
        "Allgemeine Geschäftsbedingungen und Datenschutzerklärung",
      "Contact Support": "Kontaktieren Sie Support",
      "United States Dollar": "US-Dollar",
      Euro: "Euro",
      "British Pound": "Britisches Pfund",
      "Indian Rupee": "Indische Rupie",
      "Australian Dollar": "Australischer Dollar",
      "Canadian Dollar": "Kanadischer Dollar",
      "Chinese Yuan": "Chinesische Yuan",
      "Turkish Lira": "Türkische Lire",
      English: "Englisch",
      Español: "Spanisch",
      Italiano: "Italienisch",
      Polish: "Polieren",
      Deutsch: "Deutsch",
      French: "Französisch",
      Hindi: "Nö",
      Arabic: "Arabisch",
      Mandarin: "Mandarin",
      Japanese: "japanisch",
      System: "System",
      Light: "Hell",
      Dark: "Dunkel",
      "Hover to view the phrase":
        "Bewegen Sie den Mauszeiger, um den Ausdruck anzuzeigen",
      "These words are the keys to your wallet":
        "Diese Wörter sind der Schlüssel zu Ihrer Brieftasche",
      "Welcome back": "Willkommen zurück",
      "Manage all your domains from one wallet":
        "Verwalten Sie alle Ihre Domains von einem Wallet aus",
      Unlock: "Freischalten",
      "Forgot Password?": "Passwort vergessen?",
      "Superrare will be able to": "Superrare wird dazu in der Lage sein",
      "Connect with Web23": "Verbinden Sie sich mit Web23",
      "Connect your wallet to dApps to get started":
        "Verbinden Sie Ihre Brieftasche mit dApps, um loszulegen",
      "No sites connected": "Keine Sites verbunden",
      "Wallet 1 isn’t connected to any sites":
        "Wallet 1 ist mit keinen Websites verbunden",
      "Manually Connect": "Manuell verbinden",
      "Connecting to this wallet": "Verbinden mit dieser Brieftasche",
      "Access your wallet address, activity and balance":
        "Greifen Sie auf Ihre Wallet-Adresse, Aktivität und Ihr Guthaben zu",
      "Request approval for transactions":
        "Genehmigung für Transaktionen anfordern",
      Connect: "Anschließen",
      "Switch Wallet": "Brieftasche wechseln",
      Connecting: "Verbinden",
      "Connection successful": "Verbindung erfolgreich",
      "Connection Details": "Verbindungsdetails",
      "Remove Connection": "Verbindung entfernen",
      "Remove Connection?": "Verbindung entfernen?",
      "Are you sure you want to remove connection with Superrare?":
        "Sind Sie sicher, dass Sie die Verbindung mit Superrare entfernen möchten?",
      Cancel: "Absagen",
      Remove: "Entfernen",
      "Privacy Statement": "Datenschutzerklärung",
      "This Privacy Statement describes the privacy practices of Unlabelled for data that we collect: Through the software applications made available by us for use on or through computers and mobile devices (the “Apps”). Through our social media pages that we control from which you are accessing this Privacy Statement (collectively, our “Social Media Pages”). Through HTML-formatted email messages that we send you that link to this Privacy Statement and through your communications with us. Collectively, we refer to the Websites, the Apps and our Social Media Pages, as the “Online Services” and, together with offline channels, the “Services.” By using the Services, you agree to the terms and conditions of this Privacy Statement.":
        "Diese Datenschutzerklärung beschreibt die Datenschutzpraktiken von Unlabelled für Daten, die wir sammeln: Durch die Softwareanwendungen, die von uns zur Verwendung auf oder über Computer und Mobilgeräte (die „Apps“) bereitgestellt werden. Über unsere von uns kontrollierten Social-Media-Seiten, von denen aus Sie auf diese Datenschutzerklärung zugreifen (zusammen unsere „Social-Media-Seiten“). Durch E-Mail-Nachrichten im HTML-Format, die wir Ihnen mit diesem Link zu dieser Datenschutzerklärung senden, und durch Ihre Kommunikation mit uns. Zusammen bezeichnen wir die Websites, die Apps und unsere Social-Media-Seiten als die „Online-Dienste“ und zusammen mit den Offline-Kanälen als die „Dienste“. Durch die Nutzung der Dienste stimmen Sie den Bedingungen dieser Datenschutzerklärung zu.",
      "“Personal Data” are data that identify you as an individual or relate to an identifiable individual. At touch points throughout your guest journey, we sometimes collect Personal Data such as: Email address":
        "„Personenbezogene Daten“ sind Daten, die Sie als Einzelperson identifizieren oder sich auf eine identifizierbare Einzelperson beziehen. An Berührungspunkten während Ihrer Guest Journey erfassen wir manchmal personenbezogene Daten wie: E-Mail-Adresse",
      "We collect certain data from cookies, which are pieces of data stored directly on the computer or mobile device that you are using. Cookies allow us to collect data such as browser type, time spent on the Online Services, pages visited, referring URL, language preferences, and other aggregated traffic data. We use the data for security purposes, to facilitate navigation, to display data more effectively, to collect statistical data, to personalize your experience while using the Online Services and to recognize your computer to assist your use of the Online Services. We also gather statistical data about use of the Online Services to continually improve design and functionality, understand how they are used and assist us with resolving questions.":
        "Wir erfassen bestimmte Daten aus Cookies, die direkt auf dem von Ihnen verwendeten Computer oder Mobilgerät gespeichert werden. Cookies ermöglichen es uns, Daten wie Browsertyp, mit den Onlinediensten verbrachte Zeit, besuchte Seiten, verweisende URL, Spracheinstellungen und andere aggregierte Verkehrsdaten zu sammeln. Wir verwenden die Daten für Sicherheitszwecke, um die Navigation zu erleichtern, Daten effektiver anzuzeigen, statistische Daten zu sammeln, Ihre Erfahrung bei der Nutzung der Online-Dienste zu personalisieren und Ihren Computer zu erkennen, um Sie bei der Nutzung der Online-Dienste zu unterstützen. Wir sammeln auch statistische Daten über die Nutzung der Onlinedienste, um Design und Funktionalität kontinuierlich zu verbessern, zu verstehen, wie sie verwendet werden, und uns bei der Lösung von Fragen zu unterstützen.",
      "Integrate your Domains from leading providers":
        "Integrieren Sie Ihre Domains von führenden Anbietern",
      "Mint NFTs at costs starting at $1.00 (paid in HBAR)":
        "Mint NFTs zu Kosten ab 1,00 $ (bezahlt in HBAR)",
      "Create beautiful websites with  Smart Pages":
        "Erstellen Sie schöne Websites mit Smart Pages",
      "Get access to premium Web3 Domains from Web23":
        "Erhalten Sie Zugriff auf Premium-Web3-Domains von Web23",
      "Lowest Carbon Footprint in the entire ecosystem":
        "Niedrigster CO2-Fußabdruck im gesamten Ökosystem",
      "Build & grow a community and reward them with your token":
        "Bauen und erweitern Sie eine Community und belohnen Sie sie mit Ihrem Token",
      Continue: "Weitermachen",
      "Create / Import a Wallet": "Wallet erstellen / importieren",
      "What would you like to do?": "Was würdest du gern tun?",
      "Create a New Wallet or Import an Existing Wallet on the Hedera Ecosystem":
        "Erstellen Sie eine neue Brieftasche oder importieren Sie eine vorhandene Brieftasche im Hedera-Ökosystem",
      "Create / Manage Domains": "Domänen erstellen / verwalten",
      "Manage your Domains on GoDaddy, Namecheap and other providers":
        "Verwalten Sie Ihre Domains bei GoDaddy, Namecheap und anderen Anbietern",
      "Supported Providers": "Unterstützte Anbieter",
      "Change to": "Ändern",
      "word phrase": "Wortphrase",
      "Select Domain Provider": "Wählen Sie Domänenanbieter aus",
      "Continue using": "Weiter verwenden",
      "Copy the Keys to proceed": "Kopieren Sie die Schlüssel, um fortzufahren",
      "To finish connecting your account, please click on “Copy and Continue” button.":
        "Um die Verbindung Ihres Kontos abzuschließen, klicken Sie bitte auf die Schaltfläche „Kopieren und fortfahren“.",
      Key: "Taste",
      Secret: "Geheimnis",
      "Copy and Continue": "Kopieren und fortfahren",
      "Do not match, please confirm and try again":
        "Keine Übereinstimmung, bitte bestätigen und erneut versuchen",
      "The Minimum password length is 12":
        "Die Mindestpasswortlänge beträgt 12",
      Required: "Erforderlich",
      "Setup a password to unlock your Web23 account":
        "Richten Sie ein Passwort ein, um Ihr Web23-Konto zu entsperren",
      "Enter Secret Phrase or Private Key":
        "Geben Sie die geheime Phrase oder den privaten Schlüssel ein",
      "You are on Testnet": "Sie sind auf Testnet",
      Add: "Hinzufügen",
      "Valid until": "Gültig bis",
      "Expiring in": "Ablauf in",
      days: "Tage",
      "Get your Domain": "Holen Sie sich Ihre Domain",
      "Your Hedera ID": "Ihre Hedera-ID",
      unknown: "Unbekannt",
      "Your QR Code": "Ihr QR-Code",
      "Copied to clipboard": "In die Zwischenablage kopiert",
      "Deposit HBAR": "Hinterlegen Sie HBAR",
      "Buy HBAR using Transak": "",
      "Buy using Banxa or Moonpay": "",
      "Associating or Dissociating with your wallet costs $0.05. Do you want to continue?":
        "",
      Confirm: "",
      "Association or Dissociation is succeeded.": "",
      "Association or Dissociation is failed.": "",
      "Your wallets": "",
      "Successfully saved": "",
      "Successfully deleted": "",
      "Buy Assets": "",
      "Associate Token": "",
      "Swap Tokens": "",
      "Swap from": "",
      "Enter amount": "",
      "SWAP ALL": "",
      Balance: "",
      "Swap to": "",
      "Choose Token": "",
      "Current Balance": "",
      "Max Slippage": "",
      "Transaction Fees": "",
      "Review Swap": "",
      "Search Currency": "",
      "Your transaction will not go through if the price changes more than this percentage":
        "",
      "Swap Initiated": "",
      "Your transaction is initiated and will go through in a few minutes. We shall keep you updated.":
        "",
      Review: "",
      "Choose token to send": "",
      "Edit Contact": "",
      "Enter name": "",
      "Review Transaction": "",
      "Add Memo(optional)": "",
      "Confirm transaction": "",
      "Invalid operation": "",
      "Transaction Initiated": "",
      "Kindly complete the transaction in Banxa window": "",
      "Pay using": "",
      "You'll receive": "",
      "Order Initiated": "",
      "wants to connect with your wallet": "",
      "will be able to": "",
      "Choose the wallet to connect with": "",
      "is connected to your wallet": "",
      "Are you sure you want to remove connection with": "",
      "Email us": "",
      Discord: "",
      Twitter: "",
    },
  },
};

export { de };
