const polish = {
  polish: {
    translation: {
      "Create a new Wallet": "Utwórz nowy portfel",
      "Import an existing wallet": "Zaimportuj istniejący portfel",
      "Secret Recovery Phrase": "Tajne zdanie odzyskiwania",
      "Please write them down or store it somewhere safe.":
        "Zapisz je lub schowaj w bezpiecznym miejscu.",
      "Copy to clipboard": "Skopiuj do schowka",
      "I have securely saved it": "Bezpiecznie go zapisałem",
      "Copied secret phrase": "Skopiowane tajne zdanie",
      "Create Password": "Stwórz hasło",
      "Setup a password to unlock your super wallet":
        "Ustaw hasło, aby odblokować superportfel",
      "Enter password": "Wprowadź hasło",
      "Confirm password": "Potwierdź hasło",
      "I agree with the terms and Conditions":
        "Zgadzam się z Warunkami korzystania z usługi",
      "I agree with the": "zgadzam się z",
      "terms and Conditions": "zasady i warunki",
      Proceed: "Przystępować",
      "You are all set!": "Wszystko gotowe!",
      "Congratulations!": "Gratulacje!",
      "Your Hedera super wallet is ready to go":
        "Twój super portfel Hedera jest gotowy do użycia",
      "Let’s see my dashboard": "Zobaczmy mój pulpit nawigacyjny",
      "Enter 12, 24 word secret recovery phrase or private key to import your existing wallet":
        "Wprowadź 12, 24 słowa tajnej frazy odzyskiwania lub klucza prywatnego, aby zaimportować istniejący portfel",
      "Enter Secret Phase": "Wejdź w tajną fazę",
      "WALLET VALUE": "WARTOŚĆ PORTFELA",
      Send: "Wysłać",
      Purchase: "Zakup",
      Receive: "Odbierać",
      Swap: "Zamiana",
      "My Domains": "Moje domeny",
      All: "Wszystko",
      "Manage Domains": "Zarządzaj domenami",
      "200+ extensions available": "Ponad 200 dostępnych rozszerzeń",
      "Get a Domain": "Zdobądź domenę",
      "Available Tokens": "Dostępne tokeny",
      "Manage Tokens": "Zarządzaj tokenami",
      "My wallets": "Moje portfele",
      "Create new wallet": "Utwórz nowy portfel",
      Wallets: "Portfele",
      Mainnet: "Sieć główna",
      "All your real transactions here":
        "Wszystkie Twoje prawdziwe transakcje tutaj",
      Testnet: "Sieć testowa",
      "Used for testing only": "Używany tylko do testów",
      "Edit Wallet": "Edytuj portfel",
      Delete: "Usunąć",
      "Wallet name": "Nazwa portfela",
      "Wallet Customization": "Personalizacja portfela",
      Initial: "Wstępny",
      Character: "Postać",
      Icon: "Ikona",
      "Wallet Icon": "Ikona portfela",
      "Save changes": "Zapisz zmiany",
      "Connected Site": "Połączona witryna",
      "All your Domains here": "Wszystkie Twoje domeny tutaj",
      "Manage & Track all your domains through your Web23 wallet":
        "Zarządzaj i śledź wszystkie swoje domeny za pośrednictwem portfela Web23",
      "Get Started": "Zaczynaj",
      "Nothing to see here": "Nic tu nie widać",
      "Add / Transfer / Get your new Domain today":
        "Dodaj / Przenieś / Zdobądź nową domenę już dziś",
      "Search by name or address": "Szukaj według nazwy lub adresu",
      PASTE: "PASTA",
      "Enabled Tokens": "Włączone tokeny",
      "Associating a token with your wallet costs $0.05":
        "Powiązanie tokena z portfelem kosztuje 0,05 USD",
      "Search results for": "Wyniki wyszukiwania dla",
      Settings: "Ustawienia",
      Preferences: "Preferencje",
      "Expand View": "Rozwiń widok",
      Language: "Język",
      Currency: "Waluta",
      Network: "Sieć",
      Theme: "Temat",
      "Secret Phrase": "Tajne zdanie",
      "Connected Sites": "Połączone witryny",
      "Lock Wallet": "Zablokuj portfel",
      "Terms and Privacy": "Warunki i prywatność",
      "Contact Support": "Skontaktuj się z pomocą techniczną",
      "United States Dollar": "Dolar amerykański",
      Euro: "Euro",
      "British Pound": "Funt brytyjski",
      "Indian Rupee": "rupia indyjska",
      "Australian Dollar": "Dolar australijski",
      "Canadian Dollar": "Dolar kanadyjski",
      "Chinese Yuan": "Chiński juan",
      "Turkish Lira": "Lira turecka",
      English: "język angielski",
      Español: "hiszpański",
      Italiano: "Włoski",
      Polish: "Polski",
      Deutsch: "Niemiecki",
      French: "Francuski",
      Hindi: "Nie",
      Arabic: "arabski",
      Mandarin: "Mandarynka",
      Japanese: "język japoński",
      System: "System",
      Light: "Światło",
      Dark: "Ciemny",
      "Hover to view the phrase": "Najedź kursorem, aby zobaczyć frazę",
      "These words are the keys to your wallet":
        "Te słowa to klucze do twojego portfela",
      "Welcome back": "Witamy spowrotem",
      "Manage all your domains from one wallet":
        "Zarządzaj wszystkimi domenami z jednego portfela",
      Unlock: "Odblokować",
      "Forgot Password?": "Zapomniałeś hasła?",
      "Superrare will be able to": "Superrare będzie w stanie",
      "Connect with Web23": "Połącz się z Web23",
      "Connect your wallet to dApps to get started":
        "Aby rozpocząć, połącz swój portfel z dApps",
      "No sites connected": "Brak połączonych witryn",
      "Wallet 1 isn’t connected to any sites":
        "Portfel 1 nie jest połączony z żadną witryną",
      "Manually Connect": "Połącz ręcznie",
      "Connecting to this wallet": "Łączenie z tym portfelem",
      "Access your wallet address, activity and balance":
        "Uzyskaj dostęp do adresu portfela, aktywności i salda",
      "Request approval for transactions": "Poproś o zatwierdzenie transakcji",
      Connect: "Łączyć",
      "Switch Wallet": "Zmień portfel",
      Connecting: "Złączony",
      "Connection successful": "Połączenie pomyślne",
      "Connection Details": "Szczegóły połączenia",
      "Remove Connection": "Usuń połączenie",
      "Remove Connection?": "Usunąć połączenie?",
      "Are you sure you want to remove connection with Superrare?":
        "Czy na pewno chcesz usunąć połączenie z Superrare?",
      Cancel: "Anuluj",
      Remove: "Usunąć",
      "Privacy Statement": "Oświadczenie o ochronie prywatności",
      "This Privacy Statement describes the privacy practices of Unlabelled for data that we collect: Through the software applications made available by us for use on or through computers and mobile devices (the “Apps”). Through our social media pages that we control from which you are accessing this Privacy Statement (collectively, our “Social Media Pages”). Through HTML-formatted email messages that we send you that link to this Privacy Statement and through your communications with us. Collectively, we refer to the Websites, the Apps and our Social Media Pages, as the “Online Services” and, together with offline channels, the “Services.” By using the Services, you agree to the terms and conditions of this Privacy Statement.":
        "Niniejsze Oświadczenie o ochronie prywatności opisuje praktyki prywatności firmy Unlabelled w odniesieniu do danych, które zbieramy: Za pośrednictwem aplikacji udostępnianych przez nas do użytku na komputerach i urządzeniach mobilnych („Aplikacje”) lub za ich pośrednictwem. Za pośrednictwem naszych kontrolowanych przez nas stron w mediach społecznościowych, z których uzyskujesz dostęp do niniejszego Oświadczenia o ochronie prywatności (łącznie nasze „Strony w mediach społecznościowych”). Za pośrednictwem wiadomości e-mail w formacie HTML, które wysyłamy do Ciebie, odsyłacz do niniejszego Oświadczenia o ochronie prywatności oraz za pośrednictwem Twojej komunikacji z nami. Łącznie określamy Witryny, Aplikacje i nasze Strony w mediach społecznościowych jako „Usługi online”, a wraz z kanałami offline jako „Usługi”. Korzystając z Usług, wyrażasz zgodę na warunki niniejszego Oświadczenia o ochronie prywatności.",
      "“Personal Data” are data that identify you as an individual or relate to an identifiable individual. At touch points throughout your guest journey, we sometimes collect Personal Data such as: Email address":
        "„Dane osobowe” to dane, które identyfikują użytkownika jako osobę fizyczną lub odnoszą się do możliwej do zidentyfikowania osoby fizycznej. W punktach styku podczas podróży gościa czasami zbieramy dane osobowe, takie jak: Adres e-mail",
      "We collect certain data from cookies, which are pieces of data stored directly on the computer or mobile device that you are using. Cookies allow us to collect data such as browser type, time spent on the Online Services, pages visited, referring URL, language preferences, and other aggregated traffic data. We use the data for security purposes, to facilitate navigation, to display data more effectively, to collect statistical data, to personalize your experience while using the Online Services and to recognize your computer to assist your use of the Online Services. We also gather statistical data about use of the Online Services to continually improve design and functionality, understand how they are used and assist us with resolving questions.":
        "Zbieramy określone dane z plików cookie, które są fragmentami danych przechowywanych bezpośrednio na komputerze lub urządzeniu mobilnym, z którego korzystasz. Pliki cookie umożliwiają nam gromadzenie danych, takich jak typ przeglądarki, czas spędzony w Usługach sieciowych, odwiedzane strony, odsyłający adres URL, preferencje językowe i inne zagregowane dane o ruchu. Używamy danych do celów bezpieczeństwa, aby ułatwić nawigację, efektywniej wyświetlać dane, zbierać dane statystyczne, personalizować Twoje doświadczenia podczas korzystania z Usług sieciowych oraz rozpoznawać Twój komputer, aby pomóc Ci w korzystaniu z Usług sieciowych. Gromadzimy również dane statystyczne dotyczące korzystania z Usług sieciowych w celu ciągłego ulepszania projektu i funkcjonalności, zrozumienia, w jaki sposób są one używane i pomagania nam w rozwiązywaniu problemów.",
      "Integrate your Domains from leading providers":
        "Zintegruj swoje domeny od wiodących dostawców",
      "Mint NFTs at costs starting at $1.00 (paid in HBAR)":
        "Wybijaj NFT po kosztach zaczynających się od 1,00 $ (płatne w HBAR)",
      "Create beautiful websites with  Smart Pages":
        "Twórz piękne strony internetowe za pomocą Smart Pages",
      "Get access to premium Web3 Domains from Web23":
        "Uzyskaj dostęp do domen premium Web3 z Web23",
      "Lowest Carbon Footprint in the entire ecosystem":
        "Najniższy ślad węglowy w całym ekosystemie",
      "Build & grow a community and reward them with your token":
        "Buduj i rozwijaj społeczność i nagradzaj ją swoim żetonem",
      Continue: "Kontynuować",
      "Create / Import a Wallet": "Utwórz / zaimportuj portfel",
      "What would you like to do?": "Co chciałbyś robić?",
      "Create a New Wallet or Import an Existing Wallet on the Hedera Ecosystem":
        "Utwórz nowy portfel lub zaimportuj istniejący portfel w ekosystemie Hedera",
      "Create / Manage Domains": "Twórz domeny / zarządzaj nimi",
      "Manage your Domains on GoDaddy, Namecheap and other providers":
        "Zarządzaj swoimi domenami w GoDaddy, Namecheap i innych dostawcach",
      "Supported Providers": "Obsługiwani dostawcy",
      "Change to": "Zmień na",
      "word phrase": "fraza słowna",
      "Select Domain Provider": "Wybierz dostawcę domeny",
      "Continue using": "Kontynuuj używanie",
      "Copy the Keys to proceed": "Skopiuj klucze, aby kontynuować",
      "To finish connecting your account, please click on “Copy and Continue” button.":
        "Aby zakończyć łączenie konta, kliknij przycisk „Kopiuj i kontynuuj”.",
      Key: "Klucz",
      Secret: "Sekret",
      "Copy and Continue": "Kopiuj i kontynuuj",
      "Do not match, please confirm and try again":
        "Nie pasują, potwierdź i spróbuj ponownie",
      "The Minimum password length is 12": "Minimalna długość hasła to 12",
      Required: "Wymagany",
      "Setup a password to unlock your Web23 account":
        "Ustaw hasło, aby odblokować swoje konto Web23",
      "Enter Secret Phrase or Private Key":
        "Wprowadź tajną frazę lub klucz prywatny",
      "You are on Testnet": "Jesteś na Testnecie",
      Add: "Dodać",
      "Valid until": "Ważne do",
      "Expiring in": "Wygasa za",
      days: "dni",
      "Get your Domain": "Zdobądź swoją domenę",
      "Your Hedera ID": "Twój identyfikator Hedery",
      unknown: "nieznany",
      "Your QR Code": "Twój kod QR",
      "Copied to clipboard": "Skopiowane do schowka",
      "Deposit HBAR": "Wpłać HBAR",
      "Buy HBAR using Transak": "",
      "Buy using Banxa or Moonpay": "",
      "Associating or Dissociating with your wallet costs $0.05. Do you want to continue?":
        "",
      Confirm: "",
      "Association or Dissociation is succeeded.": "",
      "Association or Dissociation is failed.": "",
      "Your wallets": "",
      "Successfully saved": "",
      "Successfully deleted": "",
    },
  },
};

export { polish };
